import { useEffect } from 'react';

import { brazeTracker } from './brazeTracker';
import { useBrazeEvent } from './useBrazeEvent';

interface BrazeSdkProps {
  brazeApiKey?: string;
  contentSecurityNonce: string;
}

export const useInitBraze = ({ brazeApiKey, contentSecurityNonce }: BrazeSdkProps) => {
  const { checkUtmCampaign } = useBrazeEvent();

  useEffect(() => {
    if (brazeApiKey && typeof window !== 'undefined') {
      const isDev = ['dev', 'localhost', 'alpha', 'beta'].some((env) => window.location.origin.includes(env));

      brazeTracker.initializeBraze(brazeApiKey, contentSecurityNonce, isDev).then(() => {
        if (typeof localStorage !== 'undefined' && localStorage !== null) {
          const uuid = localStorage.getItem('uuid');
          uuid && brazeTracker.changeUser(uuid);
          checkUtmCampaign();
          brazeTracker.toggleLogging();
        }
      });
    }
  }, [brazeApiKey, checkUtmCampaign, contentSecurityNonce]);
};
