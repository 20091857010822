import { isBrowser } from '@croquiscom/web2app';

export const isToss = () => {
  if (typeof navigator === 'undefined') {
    return false;
  }
  const user_agent = navigator.userAgent;
  if (isBrowser()) {
    return /TossApp/i.test(user_agent);
  } else {
    return false;
  }
};
