import { createLoader } from './getKakaoAPI';

/**
 * 카카오 API (Kakao)를 비동기로 불러와서 반환합니다. 이 함수의 실행이 끝난 뒤에는
 * window.Kakao를 사용할 수 있게 됩니다.
 *
 * @see https://developers.kakao.com/sdk/reference/js/release/Kakao.html
 */
export const getTossAPI = createLoader((): Promise<any> => {
  const window_any = window as any;
  if (window_any.TossOauth != null) {
    return Promise.resolve(window_any.TossOauth);
  }
  return new Promise((resolve, reject) => {
    const script_elem = document.createElement('script');
    script_elem.onerror = (e) => reject(e);
    script_elem.onload = () => {
      const TossOauth = window_any.TossOauth;
      if (TossOauth == null) {
        reject(new Error('Toss API를 불러올 수 없습니다.'));
      }
      resolve(TossOauth);
    };
    script_elem.src = 'https://cdn.toss.im/oauth2/v1';
    script_elem.async = true;
    document.head.appendChild(script_elem);
  });
});
