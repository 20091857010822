import 'dayjs/locale/ko';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('ko');
dayjs.extend(relativeTime, {
  thresholds: [
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'hh', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'dd', r: 1 },
    { l: 'dd', d: 'day' },
  ],
});

export const relativeTimeFromNow = (date: number) => {
  if (date >= dayjs().subtract(1, 'm').valueOf()) {
    return '방금';
  }

  return dayjs(date).fromNow();
};
