import { isBrowser } from '@croquiscom/web2app';

import { getConfigValue } from '@common/config';
import { isKakaotalk, isToss, isZigzag } from '@common/device-manager';
import { getSiteIdFromHost, SiteId } from '@common/site-manager';
import { loginKakao, loginToss } from '@common/utils';

export type GotoLoginParams = {
  redirect_url?: string;
  auth_login_route?: string;
};

/**
 *
 * @param auth_login_route 지정하지 않으면 config의 auth_login_route 또는 기본값(/auth/login)으로 이동
 * @param redirect_url 로그인 성공 후 돌아갈 페이지, 지정될 경우 ?redirect= 쿼리스트링이 붙으니 사용하는 앱의 인증관련 로직도 이에 맞춰야함
 * @returns void, 로그인 딥링크, 또는 로그인 웹링크로 이동
 */
export function gotoLogin({ auth_login_route, redirect_url }: GotoLoginParams = {}) {
  if (!isBrowser()) {
    return console.log('[Error] gotoLogin not available in server');
  }
  const site_id = getSiteIdFromHost();
  const auth_login_route_config = getConfigValue('auth_login_route');
  const login_route = auth_login_route ?? auth_login_route_config ?? '/auth/login';

  if (site_id === SiteId.ZIGZAG) {
    if (isZigzag()) {
      return window.location.assign('zigzag://open/login');
    }
    if (isKakaotalk()) {
      loginKakao(redirect_url);
      return;
    }
    if (isToss()) {
      loginToss(redirect_url);
      return;
    }
  }

  const redirect = !redirect_url ? '' : `?redirect=${redirect_url}`;
  window.location.replace(login_route + redirect);
}
