import { getRuntimeConfigValue } from '@common/next-runtime-config';
import { SiteId } from '@common/site-manager';

import { useInitBraze } from '../../braze';
import { GoogleAnalytics } from './GoogleAnalytics';

interface ZigzagWebMarketingScriptProps {
  contentSecurityNonce: string;
}

export const ZigzagWebMarketingScript: React.FC<ZigzagWebMarketingScriptProps> = ({ contentSecurityNonce }) => {
  const site_id = SiteId.ZIGZAG;
  const is_apps_store = false;

  const braze_api_key = getRuntimeConfigValue('braze_api_key', site_id, is_apps_store) as string;

  useInitBraze({ brazeApiKey: braze_api_key, contentSecurityNonce });

  return (
    <>
      <GoogleAnalytics />
    </>
  );
};
