import { ReactElement, ReactNode, useEffect, useRef } from 'react';
import { ThemeProvider, ZdsTheme } from '@croquiscom/zds';
import { Global } from '@emotion/react';
import * as Sentry from '@sentry/nextjs';
import { CurrencyMeta } from 'api2/types';
import { useClearAuthInfo } from 'components/auth/hooks/sign-up';
import Stage from 'components/toolbox/stage';
import { AppPropsContext, PageProps } from 'context/AppContext';
import { useBrowserTheme } from 'hooks/useBrowserTheme';
import { useResizeViewHeight } from 'hooks/useResizeViewHeight';
import { i18NextOptionForWebsite } from 'i18n/i18NextOptionForWebsite';
import { useTrackingPageView } from 'log/web-events/hooks/useTrackingPageView';
import { NextPage } from 'next';
import { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { Router } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo, SocialProfileJsonLd } from 'next-seo';
import { onlyWebStyle } from 'styles/onlyWebStyle';

import { AppConfigProvider } from '@common/app-config';
import { getZigzagUuidFromRequestHeadersCookie } from '@common/app-config/server';
import { web_path } from '@common/constants';
import {
  DEFAULT_CURRENCY_META as currency_meta,
  DEFAULT_USER_LOCALE as user_locale,
  I18nProvider,
  UserLocale,
} from '@common/i18n';
import { brazeStyle, ZigzagWebMarketingScript } from '@common/marketing-tracker';
import { convertToWebPDPLink } from '@common/pdp';
import { SiteId } from '@common/site-manager';
import { font, reset } from '@common/styles';
import { ThemeProvider as NewZDSThemeProvider } from '@common/styles/theme-provider';
import { Preconnect } from '@common/toolbox';
import { LikeProductProvider, LikeSubject, useLikeObserve } from '@widgets/like-product';
import { appendMessageQueryFromPath } from '@widgets/messenger';
import { ProductCardProvider, VerticalProductCardContextType } from '@widgets/product-card';

import { APP_DEFAULT_SEO } from '../next-seo.config';

import { TrackerProvider } from 'util/Tracker';

import '@common/carousel/swiper/styles.css';
import 'styles/event-modules.css';
import 'styles/zds.css';
import 'react-day-picker/style.css';

export type NextPageAddon<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
  getSentrySendData?: (props: P) => {};
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageAddon;
  pageProps: PageProps;
};

// 현재는 가로형, 세로형 링크의 동작이 같기 때문에 한벌로 쓰고, 이후 Vertical, Horizontal로 분기가 될 수 있습니다.
const productCardContextValue: VerticalProductCardContextType = {
  href: (item) => convertToWebPDPLink({ product_url: item.productUrl }),
};

export const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => page);
  // 서버에서 user-agent는 CloudFront에 의해 오염된 상태여서 클라이언트에서는 클라이언트 것을 사용한다
  const user_agent: string = (typeof navigator === 'undefined' ? pageProps.user_agent : navigator.userAgent) ?? '';
  const user_locale: UserLocale = pageProps.user_locale;
  const currency_meta: CurrencyMeta = pageProps.currency_meta;
  const contentSecurityNonce = pageProps.security_nonce as string;
  const handlePageLoad = useRef(() => {
    document.body.className += 'active';

    Sentry.configureScope((scope) => {
      scope.addEventProcessor((event) => {
        event.extra = {
          ...(Component.getSentrySendData && { ...Component.getSentrySendData(pageProps) }),
          user_locale,
        };

        return event;
      });
    });
  });

  useClearAuthInfo();
  useResizeViewHeight();
  const { theme } = useBrowserTheme();

  useTrackingPageView();

  useEffect(() => {
    handlePageLoad.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppConfigProvider zigzagUuid={pageProps.ZIGZAGUUID} isZigzagApp={false}>
      <TrackerProvider user_agent={user_agent}>
        <Sentry.ErrorBoundary>
          <ProductCardProvider
            useLikeObserver={useLikeObserve}
            verticalProductCard={productCardContextValue}
            horizontalProductCard={productCardContextValue}
          >
            <LikeProductProvider
              loginUrl={() =>
                appendMessageQueryFromPath(
                  `${web_path.auth.login}?redirect=${window.location.origin + window.location.pathname}`,
                  'availablePostLogin',
                )
              }
            >
              <LikeSubject>
                <AppPropsContext.Provider value={pageProps}>
                  <DefaultSeo {...APP_DEFAULT_SEO} />
                  <Head>
                    <meta
                      name='viewport'
                      content='width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no,viewport-fit=cover'
                    />
                    {/* zigzag.kr 사이트 소유권 확인 */}
                    <meta name='naver-site-verification' content='85adebb942c2b6871fb514e0de55960061731938' />
                    {/* www.zigzag.kr 사이트 소유권 확인 */}
                    <meta name='naver-site-verification' content='e3eb8252cc5871414c3a546ee4181a2478795dbf' />
                  </Head>
                  <Preconnect />
                  <ZigzagWebMarketingScript contentSecurityNonce={contentSecurityNonce} />
                  <SocialProfileJsonLd
                    type='Person'
                    name='지그재그 스토어'
                    url='https://www.zigzag.kr'
                    sameAs={[
                      'https://www.youtube.com/@ZIGZAG',
                      'https://www.instagram.com/zigzag_korea',
                      'https://apps.apple.com/kr/app/zigzag/id976131101',
                      'https://play.google.com/store/apps/details?id=com.croquis.zigzag',
                    ]}
                  />
                  <Global styles={reset} />
                  <Global styles={onlyWebStyle} />
                  <Global styles={font} />
                  <Global styles={brazeStyle} />
                  <I18nProvider user_locale={user_locale} currency_meta={currency_meta}>
                    <ThemeProvider name={theme as ZdsTheme['name']}>
                      <NewZDSThemeProvider colorScheme='light'>
                        {getLayout(<Component {...pageProps} />)}
                      </NewZDSThemeProvider>
                      <Stage />
                    </ThemeProvider>
                  </I18nProvider>
                </AppPropsContext.Provider>
              </LikeSubject>
            </LikeProductProvider>
          </ProductCardProvider>
        </Sentry.ErrorBoundary>
      </TrackerProvider>
    </AppConfigProvider>
  );
};

MyApp.getInitialProps = async ({ Component, ctx, router }: AppContext) => {
  // Component.getInitialProps 전에 setHeader 호출해야함
  setFrameOptions(ctx);

  const cookieString = ctx.req?.headers.cookie ?? '';
  const ZIGZAGUUID = getZigzagUuidFromRequestHeadersCookie(cookieString);

  let initial_page_props = {};
  if (Component.getInitialProps) {
    initial_page_props = await Component.getInitialProps(ctx);
  }

  const pageProps = {
    ...initial_page_props,
    user_agent: ctx.req?.headers['user-agent'] ?? '',
    user_locale,
    currency_meta,
    is_pdp: isPdp(router),
    ZIGZAGUUID,
    _nextI18Next: {
      initialLocale: user_locale.language,
    },
    ROUTES_TITLE_DATA: await getPublicLocale(user_locale.language),
  };

  return { pageProps };
};

async function getPublicLocale(language: unknown): Promise<Record<string, string>> {
  try {
    if (typeof window === 'undefined') {
      return process.env.LOCALE_HASH
        ? require(`../public/locales/${language}/route-title.${process.env.LOCALE_HASH}.json`)
        : require(`../public/locales/${language}/route-title.json`);
    } else {
      const ROUTES_TITLE_DATA = await fetch(`/locales/${language}/route-title.${process.env.LOCALE_HASH}.json`).then(
        (res) => res.json(),
      );
      return ROUTES_TITLE_DATA;
    }
  } catch (error) {
    console.error(error);
    return {};
  }
}

export default appWithTranslation(MyApp, i18NextOptionForWebsite);

function isPdp(router: Router) {
  try {
    return router.asPath.includes('/catalog/products/') || router.asPath.includes('/raffle/detail/');
  } catch (error) {
    return false;
  }
}

function setFrameOptions({ req, res }: AppContext['ctx']) {
  if (!req || !res) {
    return;
  }
  try {
    const { hostname } = new URL(req.headers.referer || '');
    if (hostname === 'localhost' || hostname.endsWith('zigzag.kr') || hostname.endsWith('kakaostyle.com')) {
      return;
    } else {
      res.setHeader('X-Frame-Options', 'DENY');
    }
  } catch (e) {
    res.setHeader('X-Frame-Options', 'DENY');
  }
}
