type LinkProps = {
  browsing_type: string;
  catalog_product_id: string | null;
  product_url: string | null;
  shop_id: string;
  shop_product_no: string | null;
};

export const convertToPDPDeepLink = ({
  browsing_type,
  catalog_product_id,
  product_url,
  shop_id,
  shop_product_no,
}: LinkProps) => {
  if (browsing_type === 'NORMAL') {
    return `zigzag://${product_url}`;
  }

  if (product_url?.includes('/z-gift/')) {
    return `zigzag://open/simple_browser?url=${encodeURIComponent(product_url)}`;
  }

  const search = new URLSearchParams({
    zz_origin: 'coupon_collection',
    browsing_type,
  });
  product_url && search.set('url', product_url);
  shop_id && search.set('shop_id', shop_id);
  shop_product_no && search.set('shop_product_no', shop_product_no);
  catalog_product_id && search.set('catalog_product_id', catalog_product_id);
  catalog_product_id && search.set('z_pid', catalog_product_id);

  return `zigzag://product_detail?${search.toString()}`;
};

export const convertToWebPDPLink = ({ product_url }: Pick<LinkProps, 'product_url'>) => {
  const webUrlRecord = new URL((product_url ?? '').replace('/app/catalog/products', '/catalog/products'));
  return webUrlRecord.pathname;
};
