import { UserNotificationCategory } from './notificationCategory';
import { CATAGORY_POINT, CATEGORIES_WITH_POINT } from './notificationKeys';

export enum NotificationTab {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
  ORDER = 'ORDER',
  RESTOCK = 'RESTOCK',
  NORMAL = 'NORMAL',
  EXPIRED_USER_BENEFIT = 'EXPIRED_USER_BENEFIT',
  INFORMATION = 'INFORMATION',
  REVIEW = 'REVIEW',
  SAVED_PRODUCTS = 'SAVED_PRODUCTS',
}

export interface Tab {
  name: string;
  type: NotificationTab;
  log_id: string;
  sequence: number;
  categories: CATEGORIES_WITH_POINT[];
  is_unread?: boolean;
}

export const TAB_LIST: Record<NotificationTab, Tab> = {
  [NotificationTab.ALL]: {
    name: '전체',
    type: NotificationTab.ALL,
    log_id: 'all',
    sequence: 1,
    categories: [],
  },
  [NotificationTab.UNREAD]: {
    name: '읽지 않음',
    type: NotificationTab.UNREAD,
    log_id: 'unread',
    sequence: 1,
    categories: [],
  },
  [UserNotificationCategory.ORDER]: {
    name: '주문/배송',
    type: NotificationTab.ORDER,
    log_id: 'order',
    sequence: 2,
    categories: [UserNotificationCategory.ORDER],
  },
  [UserNotificationCategory.RESTOCK]: {
    name: '재입고',
    type: NotificationTab.RESTOCK,
    log_id: 'restock',
    sequence: 4,
    categories: [UserNotificationCategory.RESTOCK],
  },
  [UserNotificationCategory.NORMAL]: {
    name: '혜택/이벤트',
    type: NotificationTab.NORMAL,
    log_id: 'benefit_event',
    sequence: 3,
    categories: [
      UserNotificationCategory.NORMAL,
      UserNotificationCategory.ZPAY,
      UserNotificationCategory.DAILY_MISSION,
      UserNotificationCategory.BEAUTY,
      UserNotificationCategory.SALE_TAB,
    ],
  },
  [UserNotificationCategory.SAVED_PRODUCTS]: {
    name: '찜한 상품',
    type: NotificationTab.SAVED_PRODUCTS,
    log_id: 'saved_product',
    sequence: 5,
    categories: [UserNotificationCategory.SAVED_PRODUCTS],
  },
  [UserNotificationCategory.EXPIRED_USER_BENEFIT]: {
    name: '포인트',
    type: NotificationTab.EXPIRED_USER_BENEFIT,
    log_id: 'point',
    sequence: 6,
    categories: [CATAGORY_POINT.POINT, UserNotificationCategory.EXPIRED_USER_BENEFIT], // UserNotificationCategory 에 POINT 가 없어서 임시로 추가 -> 스토어소식에서 포인트로 이동
  },
  [UserNotificationCategory.REVIEW]: {
    name: '커뮤니티',
    type: NotificationTab.REVIEW,
    log_id: 'community',
    sequence: 7,
    categories: [UserNotificationCategory.REVIEW, UserNotificationCategory.EPICK, UserNotificationCategory.TALK_LOUNGE],
  },
  [UserNotificationCategory.INFORMATION]: {
    name: '고객센터',
    type: NotificationTab.INFORMATION,
    log_id: 'customer_center',
    sequence: 10,
    categories: [UserNotificationCategory.INFORMATION],
  },
};
