import React from 'react';
import { callDeepLink, callZigzagFunction } from '@croquiscom/web2app';
import { IconArrowLeftBold32, IconSettingBold32 } from '@croquiscom/zds';
import { vars } from '@croquiscom-pvt/zds/tokens';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useUBL } from '@common/log-manager';
import { safeAreaInsetTop, Typography } from '@common/styles';

interface Props extends React.HTMLProps<HTMLDivElement> {
  title: string;
  paddingTop?: number;
  hasSettingIcon?: boolean;
  onClickBack?: () => void;
}

export const NotificationHeader: React.FC<Props> = ({
  title,
  paddingTop = 0,
  hasSettingIcon = false,
  onClickBack,
  children,
}) => {
  const sendUBL = useUBL();

  const theme = useTheme();
  const is_dark_theme = theme.name === 'dark';
  const theme_color = vars.color.textStrong;
  const theme_icon_color = vars.color.iconPrimary;

  const handleClickBack = () => {
    if (!onClickBack) {
      callZigzagFunction('back')();
    } else {
      onClickBack();
    }
  };

  const onClickMoveSettings = () => {
    const url = '/notification/settings';
    sendUBL({
      category: 'click',
      navigation: 'my_notifications',
      navigation_sub: { page_url: window.location.href },
      object_type: 'button',
      object_id: 'settings',
    });
    sendUBL({
      category: 'pageview',
      navigation: 'simple_browser',
      navigation_sub: { page_url: `${window.location.origin}${url}` },
    });
    callDeepLink(url)();
  };

  return (
    <Container>
      <Header paddingTop={paddingTop}>
        <Bar>
          <BackIcon color={theme_icon_color} onClick={handleClickBack} />
          <Title color={theme_color} className={Typography.BODY_17_BOLD}>
            {title}
          </Title>
          {hasSettingIcon && <SettingIcon color={theme_icon_color} onClick={onClickMoveSettings} />}
        </Bar>
      </Header>
      {children}
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${vars.color.backgroundSurface};
`;

const Header = styled.header<{ paddingTop: number }>`
  ${({ paddingTop }) => safeAreaInsetTop(`${paddingTop}px`)}
`;

const Bar = styled.div`
  height: 44px;
  position: relative;
  text-align: center;
`;

const Title = styled.h1<{ color: string }>`
  line-height: 44px;
  vertical-align: middle;
  letter-spacing: -0.3px;
  color: ${({ color }) => color};
`;

const BackIcon = styled(IconArrowLeftBold32)`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
`;

const SettingIcon = styled(IconSettingBold32)`
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 3;
  transform: translateY(-50%);
`;
