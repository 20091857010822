import { web_path } from '@common/constants';

import { TermsAgreement } from '../types';

export enum StorageKey {
  TERMS_AGREEMENT = 'terms_agreement',
  CONNECTED_SOCIAL_ACCOUNT = 'connected_social_account',
  AUTH_INFO = 'auth_info',
  USER_INFO = 'user_info',
  REDIRECT_URL = 'redirect_url',
  RECERTIFICATION_REDIRECT_URL = 'recertification_redirect_url',
  FROM_RECERTIFICATION = 'from_recertification',
}

export const SOCIAL_ACCOUNT = {
  kakao: 'kakao',
  facebook: 'facebook',
  apple: 'apple',
  google: 'google',
  toss: 'toss',
} as const;

export type AuthInfo =
  | {
      type: 'kakao';
      access_token: string;
      refresh_token: string;
    }
  | {
      type: 'facebook';
      access_token: string;
    }
  | {
      type: 'apple';
      code: string;
      bundle_id: string;
    }
  | {
      type: 'google';
      token: string;
    }
  | {
      type: 'email';
    }
  | { type: 'toss'; code: string };

export type SocialAccount = AuthInfo['type'];

export const getSocialConnectionName = (type?: string) => {
  switch (type) {
    case SOCIAL_ACCOUNT.apple:
      return '애플';
    case SOCIAL_ACCOUNT.google:
      return '구글';
    case SOCIAL_ACCOUNT.kakao:
      return '카카오';
    case SOCIAL_ACCOUNT.facebook:
      return '페이스북';
    case SOCIAL_ACCOUNT.toss:
      return '토스';
    default:
      return '';
  }
};

interface UserInfo {
  email?: string;
  mobile_tel?: string;
}

export interface ConnectedSocialAccount {
  kakao_connected: boolean | null;
  apple_connected: boolean | null;
  facebook_connected: boolean | null;
  google_connected: boolean | null;
  toss_connected: boolean | null;
}

const default_user_info = {
  email: '',
  mobile_tel: '',
};

const is_browser = typeof window !== 'undefined' && typeof sessionStorage !== 'undefined';

export class AuthSessionStorage {
  static saveData<T>(key: StorageKey, data_to_save: T) {
    try {
      if (is_browser) {
        sessionStorage.setItem(key, JSON.stringify(data_to_save));
      }
    } catch (err) {
      console.error(err);
    }
  }

  static getData<T>(key: StorageKey): T | null {
    try {
      if (is_browser) {
        return JSON.parse(sessionStorage.getItem(key)!);
      } else {
        return null;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  static clearData(key: StorageKey) {
    if (is_browser) {
      sessionStorage.removeItem(key);
    }
  }

  static saveTermsAgreement(terms_agreement: TermsAgreement) {
    this.saveData<TermsAgreement>(StorageKey.TERMS_AGREEMENT, terms_agreement);
  }

  static saveConnectedSocialAccount(connected_social_account: ConnectedSocialAccount) {
    this.saveData<ConnectedSocialAccount>(StorageKey.CONNECTED_SOCIAL_ACCOUNT, connected_social_account);
  }

  static saveAuthInfo(auth_info: AuthInfo) {
    this.saveData<AuthInfo>(StorageKey.AUTH_INFO, auth_info);
  }

  static saveEmail(email: string) {
    const data = this.getData<UserInfo>(StorageKey.USER_INFO) ?? default_user_info;
    this.saveData<UserInfo>(StorageKey.USER_INFO, { ...data, email });
  }

  static saveMobileTel(mobile_tel: string) {
    const data = this.getData<UserInfo>(StorageKey.USER_INFO) ?? default_user_info;
    this.saveData<UserInfo>(StorageKey.USER_INFO, { ...data, mobile_tel });
  }

  static saveRedirectUrl(redirect_url: string) {
    this.saveData<string>(StorageKey.REDIRECT_URL, redirect_url);
  }

  static saveRecertificationRedirectUrl(redirect_url: string) {
    this.saveData<string>(StorageKey.RECERTIFICATION_REDIRECT_URL, redirect_url);
  }

  static saveFromRecertification(from_recertification: boolean) {
    this.saveData<boolean>(StorageKey.FROM_RECERTIFICATION, from_recertification);
  }

  static getTermsAgreement(): TermsAgreement | null {
    return this.getData<TermsAgreement>(StorageKey.TERMS_AGREEMENT);
  }

  static getConnectedSocialAccount() {
    return this.getData<ConnectedSocialAccount>(StorageKey.CONNECTED_SOCIAL_ACCOUNT);
  }

  static getAuthInfo() {
    return this.getData<AuthInfo>(StorageKey.AUTH_INFO);
  }

  static getUserInfo() {
    return this.getData<UserInfo>(StorageKey.USER_INFO);
  }

  static getRedirectUrl() {
    return this.getData<string>(StorageKey.REDIRECT_URL) ?? web_path.root;
  }

  static getRecertificationRedirectUrl() {
    return this.getData<string>(StorageKey.RECERTIFICATION_REDIRECT_URL) ?? web_path.root;
  }

  static getFromRecertification() {
    return this.getData<boolean>(StorageKey.FROM_RECERTIFICATION) ?? false;
  }

  static clearTermsAgreement() {
    this.clearData(StorageKey.TERMS_AGREEMENT);
  }

  static clearConnectedSocialAccount() {
    this.clearData(StorageKey.CONNECTED_SOCIAL_ACCOUNT);
  }

  static clearAuthInfo() {
    this.clearData(StorageKey.AUTH_INFO);
  }

  static clearUserInfo() {
    this.clearData(StorageKey.USER_INFO);
  }

  static clearRedirectUrl() {
    this.clearData(StorageKey.REDIRECT_URL);
  }

  static clearFromRecertification() {
    this.clearData(StorageKey.FROM_RECERTIFICATION);
  }

  static clearAllData() {
    this.clearTermsAgreement();
    this.clearConnectedSocialAccount();
    this.clearAuthInfo();
    this.clearUserInfo();
    this.clearRedirectUrl();
    this.clearFromRecertification();
  }
}
