import {
  AirbridgeAPI,
  DeeplinkOption,
  DownloadOption,
  EventOption,
} from 'airbridge-web-sdk-loader/build/airbridge_api';

declare global {
  interface Window {
    airbridge?: AirbridgeAPI;
  }
}

/**
 * Airbridge Tracker
 * - 'airbridge.' 으로 시작하는 event의 경우 Airbridge에서 제공하는 스탠다드 이벤트. (Airbridge Standard Event)
 * - 그 외에는 카카오스타일에서 추가한 커스텀 이벤트. (Custom Event)
 * - 이벤트를 추가하면 옆에 주석으로 간략하게 설명을 추가해주세요.
 */
type AirbridgeTrackerEventsSendEventName =
  | 'airbridge.user.signin' // 로그인
  | 'airbridge.user.signup' // 회원가입
  | 'airbridge.user.signout' // 로그아웃
  | 'airbridge.ecommerce.productList.viewed' // 상품리스트 조회
  | 'airbridge.addToWishlist'
  | 'airbridge.ecommerce.product.viewed' // 상품상세조회
  | 'airbridge.ecommerce.product.addedToCart' // 장바구니담기
  | 'airbridge.ecommerce.searchResults.viewed' // 검색결과조회
  | 'airbridge.ecommerce.home.viewed' // 홈 화면 조회
  | 'signin_kakao' // 카카오 로그인
  | 'signup_kakao' // 카카오 회원가입
  | 'airbridge.ecommerce.order.completed' // 구매완료
  | 'order_completed_first' // 첫 구매 구매완료
  | 'airbridge.ecommerce.order.canceled' // 구매취소
  | 'airbridge.share' // 공유
  | 'order_completed_first_excluding-deposit' // 무통장 입금을 제외한 첫구매
  | 'order_completed_excluding-deposit' // 무통장 입금을 제외한 구매완료
  | 'order_completed_linkprice'; // 링크프라이스 구매완료

class AirbridgeTracker {
  events = {
    send: (eventName: AirbridgeTrackerEventsSendEventName, option?: EventOption) => {
      const airbridge = this.getAirbridge();
      if (!airbridge) {
        return;
      }
      try {
        // if (process.env.NODE_ENV === 'development') {
        //   console.debug('#AirbridgeTracker events.send', eventName, option);
        // }
        airbridge.events.send(eventName, option);
      } catch (e) {
        console.error('#AirbridgeTracker events.send Error', e);
      }
    },
  };

  setUserId(uuid: string) {
    const airbridge = this.getAirbridge();
    if (!airbridge) {
      return;
    }
    try {
      // if (process.env.NODE_ENV === 'development') {
      //   console.debug('#AirbridgeTracker setUserId', uuid);
      // }
      airbridge.setUserId(uuid);
    } catch (e) {
      console.error('#AirbridgeTracker setUserId Error', e);
    }
  }

  clearUser() {
    const airbridge = this.getAirbridge();
    if (!airbridge) {
      return;
    }
    try {
      // if (process.env.NODE_ENV === 'development') {
      //   console.debug('#AirbridgeTracker clearUser');
      // }
      airbridge.clearUser();
    } catch (e) {
      console.error('#AirbridgeTracker clearUser Error', e);
    }
  }

  /**
   *
   * 웹투앱 설정, setDownloads 함수를 통해 앱 다운로드 버튼을 설정하여 성과를 측정할 수 있게 해줍니다.
   * @param option
   * @docs https://help.airbridge.io/ko/developers/web-sdk#%EC%95%B1-%EB%8B%A4%EC%9A%B4%EB%A1%9C%EB%93%9C-%EB%B2%84%ED%8A%BC-%EC%84%A4%EC%A0%95
   */
  setDownloads(option: DownloadOption) {
    const airbridge = this.getAirbridge();
    if (!airbridge) {
      return;
    }
    try {
      airbridge.setDownloads(option);
    } catch (e) {
      console.error('#AirbridgeTracker setDownload Error', e);
    }
  }

  /**
   * 웹투앱 설정, setDeeplinks 함수를 통해 앱으로 보기 버튼을 설정할 수 있습니다. 앱이 설치되지 않은 경우 fallbacks 처리가 가능합니다.
   * @param option
   * @docs https://help.airbridge.io/ko/developers/web-sdk#%EC%95%B1-%EC%A4%91%EA%B3%84-%ED%8E%98%EC%9D%B4%EC%A7%80%EB%A1%9C-%EC%82%AC%EC%9A%A9
   */
  setDeeplinks(
    option: DeeplinkOption & {
      buttonID: string | string[];
    },
  ) {
    const airbridge = this.getAirbridge();
    if (!airbridge) {
      return;
    }
    try {
      airbridge.setDeeplinks(option);
    } catch (e) {
      console.error('#AirbridgeTracker setDeeplinks Error', e);
    }
  }

  /**
   * 웹투앱 설정, setDeeplinks의 함수 호출 버전으로 기능은 setDeeplinks와 비슷합니다.
   *
   * 유저의 행동을 통해 호출하는 경우에는 type을 `click`으로, 제스쳐 없이 호출하는 경우에는 `redirect`로 설정합니다.
   * @param option
   * @docs https://help.airbridge.io/ko/developers/web-sdk#%ED%95%A8%EC%88%98%ED%98%B8%EC%B6%9C%EB%A1%9C-%EC%95%B1-%EC%97%B4%EA%B8%B0
   */
  openDeeplink(
    option: DeeplinkOption & {
      type: 'click' | 'redirect';
    },
  ) {
    const airbridge = this.getAirbridge();
    if (!airbridge) {
      return;
    }
    try {
      airbridge.openDeeplink(option);
    } catch (e) {
      console.error('#AirbridgeTracker openDeeplink Error', e);
    }
  }

  getAirbridge() {
    return window.airbridge;
  }
}

export const airbridgeTracker = new AirbridgeTracker();
