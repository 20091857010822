import getConfig from 'next/config';

import { getConfigValue } from '@common/config';

import { getTossAPI } from './getTossAPI';

interface TossOauthError {
  error: string;
  error_description?: string;
}

interface TossOauthOption {
  type: 'popup' | 'push' | 'appOpen';
  client_id: string;
  redirect_uri: string;
  onSuccess?: (response: any) => void;
  onFail?: (error: TossOauthError) => void;
  oauth_policy: string;
  state?: string;
  grant_type?: string;
  _env_type?: string;
}

declare const window: Window & {
  TossOauth?: () => {
    start: (option: TossOauthOption) => void;
  };
};

export const loginToss = async (redirect_url?: string | null) => {
  const { publicRuntimeConfig } = getConfig();
  const { toss_client_id, stage } = publicRuntimeConfig.config;

  const redirect_uri = redirect_url ?? window.location.href;

  const toss_state = {
    redirect_uri,
  };

  try {
    const toss_auth = await getTossAPI();

    toss_auth().start({
      type: 'appOpen',
      client_id: toss_client_id || '', // client_id
      redirect_uri: `${location.origin}/auth/redirect-scope-toss`,
      state: encodeURIComponent(JSON.stringify(toss_state)),
      _env_type: stage !== 'production' && stage !== 'beta' ? 'alpha' : 'product',
      oauth_policy: 'LOGIN',
    });
  } catch (error) {
    console.error('toss login error', error);
  }
};
