import { BaseConfigType } from './default';
import ProductionConfig from './production';

const Config: BaseConfigType = {
  ...ProductionConfig,
  baseDomain: 'beta-rc.zigzag.kr',
  apiBaseUrl: 'https://api.beta-rc.zigzag.kr/api/store',
  apiConsumerBaseUrl: 'https://api.beta-rc.zigzag.kr/api/2',
  appUrl: 'https://store.beta-rc.zigzag.kr',
  simplePayPassword:
    'https://api.beta-rc.zigzag.kr/api/security-keypad/static/js/simple_pay/simple_pay_password_beta.js',
  contentUrl: 'https://content.beta-rc.zigzag.kr',
  resUrl: 'https://cf.res.s.zigzag.kr',
  zigzag_postcode_url: 'https://store.beta.zigzag.kr/postcode',
  zendesk_url: 'https://kakaostyle.zendesk.com/hc/p/requests-with-sso',
};

export default Config;
