import { UserNotificationCategory } from './notificationCategory';

const GET_USER_AD_NOTI_STATUS = 'getUserAdNotiStatus';
export const GET_USER_NOTIFICATION_LIST = 'getUserNotificationList';
const GET_USER_NOTIFICATION_COUNT = 'getUserNotificationCount';
const GET_MARKETING_AGREEMENT_STATUS = 'getMarketingAgreementStatus';
const GET_USER_PUSH_AGREEMENT_STATUS_LIST = 'getUserPushAgreementStatusList';
const GET_USER_NOTIFICATION_CATEGORY_LIST = 'getUserNotificationCategoryList';
const GET_USER_NOTIFICATION_SETTING_CATEGORY_LIST = 'getUserNotificationSettingCategoryList';
export enum CATAGORY_POINT {
  POINT = 'POINT',
}
export type CATEGORIES_WITH_POINT = UserNotificationCategory | CATAGORY_POINT;
export const notificationKeys = Object.freeze({
  adNotiStatus: GET_USER_AD_NOTI_STATUS,
  marketingAgreementStatus: GET_MARKETING_AGREEMENT_STATUS,
  userPushAgreementStatusList: GET_USER_PUSH_AGREEMENT_STATUS_LIST,
  userNotificationCategoryList: GET_USER_NOTIFICATION_CATEGORY_LIST,
  userNotificationSettingCategoryList: GET_USER_NOTIFICATION_SETTING_CATEGORY_LIST,
  userNotificationList: (categories: CATEGORIES_WITH_POINT[] = [], is_unread?: boolean) => [
    GET_USER_NOTIFICATION_LIST,
    ...categories,
    { is_unread },
  ],
  userNotificationCount: (categories: CATEGORIES_WITH_POINT[] = []) => [GET_USER_NOTIFICATION_COUNT, ...categories],
});
